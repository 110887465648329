import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StyledEngineProvider } from '@mui/material/styles';

import { IE_REGEXP } from '@uptime/shared/constants';
import ThemedSnackbar from '@uptime/shared/theme/ThemedSnackbar';

import App from './App';
import AuthProvider from './providers/AuthProvider';
import PermissionsProvider from './providers/PermissionsProvider';
import SourceProvider from './providers/SourceProvider';
import UIProvider from './providers/UIProvider';
import JwtProvider from './providers/JwtProvider';
import CustomThemeProvider from './providers/CustomThemeProvider';
import * as serviceWorker from './serviceWorker';
import { flowClient } from './apolloClientConfiguration';
import { CognitoProvider } from './libs/providers/CognitoProvider';
import { ThirdPartyIntegrationsProvider } from './libs/providers/ThirdPartyIntegrationsProvider';
import AuthSync from './components/AuthSync';

import './index.scss';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

if (IE_REGEXP.test(window.navigator.userAgent)) {
  window.alert(
    'Internet Explorer is not a supported Browser. ' +
      'Please use the latest version of Apple Safari, Google Chrome or Microsoft Edge.'
  );
}

const AppContainer = () => {
  return (
    <ApolloProvider client={flowClient}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <CustomThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <UIProvider>
                <AuthProvider>
                  <PermissionsProvider>
                    <SourceProvider>
                      <JwtProvider>
                        <SnackbarProvider
                          maxSnack={3}
                          autoHideDuration={6000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          Components={{
                            success: ThemedSnackbar,
                            error: ThemedSnackbar,
                            warning: ThemedSnackbar,
                            info: ThemedSnackbar,
                          }}
                        >
                          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                            <CognitoProvider>
                              <ThirdPartyIntegrationsProvider>
                                <AuthSync>
                                  <App />
                                </AuthSync>
                              </ThirdPartyIntegrationsProvider>
                            </CognitoProvider>
                          </DndProvider>
                        </SnackbarProvider>
                      </JwtProvider>
                    </SourceProvider>
                  </PermissionsProvider>
                </AuthProvider>
              </UIProvider>
            </LocalizationProvider>
          </CustomThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

ReactDOM.render(<AppContainer />, document.getElementById('root'));

serviceWorker.unregister();
