import React, { createContext, useCallback, useContext, useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { COGNITO_IDL_MESSAGE, COGNITO_KEYS, LAST_LOGIN, LOGIN_METHOD, SSO } from '@uptime/shared/constants';

// @ts-ignore
import * as cognito from 'libs/services/cognito';
import { useOnMount, useOnUpdate } from 'libs/hooks';

type Context = {
  signIn: (username: string, password: string) => Promise<any>;
  signOut: () => void;
  resetPassword: (username: string, code: string, password: string) => Promise<any>;
  forgotPassword: (username: string) => Promise<any>;
  confirmPassword: (username: string, code: string, password: string) => Promise<any>;
  isAuthorized: boolean;
  getShowIdlMessage: () => string | null | undefined;
  changePassword: (username: string, oldPassword: string, newPassword: string) => Promise<any>;
  federatedSingIn: () => any;
  getFederatedUserSession: () => Promise<any>;
  getLastLoginPath: () => string;
  federatedSignOut: () => void;
  getUser: () => Record<any, any> | undefined;
};

type Props = {
  children: React.ReactNode;
};

type CognitoError = {
  code: string;
  name: string;
};

export enum IDL_COGNITO_MESSAGE_VALUE {
  SESSION = 'SESSION',
  LOGOUT = 'LOGOUT',
  HIDE = 'HIDE',
}

export const CognitoContext = createContext<Context>({
  signIn: async (username: string, password: string) => {
    console.log(username, password);
  },
  signOut: () => {},
  resetPassword: async (username: string, code: string, password: string) => {
    console.log(username, code, password);
  },
  forgotPassword: async (username: string) => {
    console.log(username);
  },
  confirmPassword: async (username: string, code: string, password: string) => {
    console.log(username, code, password);
  },
  isAuthorized: false,
  getShowIdlMessage: () => '',
  changePassword: async (username: string, oldPassword: string, newPassword: string) => {},
  federatedSingIn: () => {},
  getFederatedUserSession: async () => {},
  getLastLoginPath: () => '',
  federatedSignOut: () => {},
  getUser: () => ({}),
});

export const useCognito = () => useContext(CognitoContext);

const getPayloads = (session) => ({
  accessTokenPayload: session.accessToken.payload,
  idTokenPayload: session.idToken.payload,
});

const getTokens = (session) => ({
  accessToken: session.accessToken.jwtToken,
  refreshToken: session.refreshToken.token,
  idToken: session.idToken.jwtToken,
});

const setTokensInStorage = ({ accessToken, refreshToken, idToken }) => {
  localStorage.setItem(COGNITO_KEYS.ACCESS_TOKEN, accessToken);
  localStorage.setItem(COGNITO_KEYS.ID_TOKEN, idToken);
  localStorage.setItem(COGNITO_KEYS.REFRESH_TOKEN, refreshToken);
};

const setPayloadsInStorage = ({ idTokenPayload, accessTokenPayload }) => {
  localStorage.setItem(COGNITO_KEYS.ACCESS_TOKEN_PAYLOAD, JSON.stringify(accessTokenPayload));
  localStorage.setItem(COGNITO_KEYS.ID_TOKEN_PAYLOAD, JSON.stringify(idTokenPayload));
};

const storeTokensInStorage = (session) => setTokensInStorage(getTokens(session));
const storePayloadsInStorage = (session) => setPayloadsInStorage(getPayloads(session));

const resetStorage = () => Object.values(COGNITO_KEYS).forEach((key: any) => localStorage.removeItem(key));

const setShowIdlMessageInStorage = (v: IDL_COGNITO_MESSAGE_VALUE) =>
  localStorage.setItem(COGNITO_IDL_MESSAGE, v);
const getShowIdlMessageFromStorage = () =>
  localStorage.getItem(COGNITO_IDL_MESSAGE) || IDL_COGNITO_MESSAGE_VALUE.HIDE;

const setLoginMethod = (method: string) => localStorage.setItem(LOGIN_METHOD, method);
const getLoginMethod = () => localStorage.getItem(LOGIN_METHOD);
const resetLoginMethod = () => localStorage.removeItem(LOGIN_METHOD);

const setLastLoginInformation = (lastLoginInfo: { pathname: string; userId?: string }) =>
  localStorage.setItem(
    LAST_LOGIN,
    JSON.stringify({
      ...lastLoginInfo,
      updatedAt: Date.now(),
    })
  );
const resetLastLoginInformation = () => localStorage.removeItem(LAST_LOGIN);

const storeDetailsInStorage = (session: CognitoUserSession) => {
  resetStorage();
  [storeTokensInStorage, storePayloadsInStorage].map((f) => f(session));
};

const ERROR_MESSAGE = {
  INVALID_USERNAME_OR_PASSWORD: 'Incorrect username or password.',
  OOPS: 'Oops, something went wrong.',
  RATE_LIMIT: 'Rate limit exceeded. Please retry later.',
  INVALID_VERIFICATION_CODE_OR_EMAIL: 'Invalid verification code or username.',
  CANNOT_RESET_USER_PASSWORD: 'Forbidden! Please contact our support.',
};

const COGNITO_ERROR = {
  LimitExceededException: 'LimitExceededException',
  UserNotFoundException: 'UserNotFoundException',
  CodeMismatchException: 'CodeMismatchException',
  NotAuthorizedException: 'NotAuthorizedException',
  InvalidParameterException: 'InvalidParameterException',
  ExpiredCodeException: 'ExpiredCodeException',
};

const ERROR_MAPPER = {
  // triggered when rate limit is reached
  [COGNITO_ERROR.LimitExceededException]: ERROR_MESSAGE.RATE_LIMIT,
  // triggered when user does not exist
  [COGNITO_ERROR.UserNotFoundException]: ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD,
  // triggered when user code does not match aws cognito code
  [COGNITO_ERROR.CodeMismatchException]: ERROR_MESSAGE.INVALID_VERIFICATION_CODE_OR_EMAIL,
  // triggered when user is disabled or email address is invalid
  [COGNITO_ERROR.NotAuthorizedException]: ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD,
  // triggered when email is not verified or invalid verification code
  [COGNITO_ERROR.InvalidParameterException]: ERROR_MESSAGE.INVALID_USERNAME_OR_PASSWORD,
  // triggered when code expired
  [COGNITO_ERROR.ExpiredCodeException]: ERROR_MESSAGE.INVALID_VERIFICATION_CODE_OR_EMAIL,
};

const getError = (error: CognitoError) => {
  const message = ERROR_MAPPER[error.code];

  if (message) {
    return message;
  }

  return ERROR_MESSAGE.OOPS;
};

const DEFAULT_PATH = '/app';
const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = ONE_MINUTE * 60;

const IGNORE_LAST_LOGIN_PATHS = [
  '/app/login',
  '/app/forgot-password',
  '/app/activation',
  '/app/callback',
  '/app/sso-login',
  '/app/signout',
];

export const CognitoProvider = ({ children }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const intervalRef = useRef(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  const { start, pause } = useIdleTimer({
    timeout: ONE_HOUR,
    onAction: () => {},
    onActive: () => {},
    onIdle: () => {
      setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.SESSION);
      setIsAuthorized(false);
    },
    debounce: 1000,
    startManually: true,
  });

  useOnMount(() => {
    const setUserSession = async () => {
      try {
        const session = await cognito.getUserSession();

        storeDetailsInStorage(session);
        setSession(session);
        setIsAuthorized(true);

        start();
      } catch (e) {
        console.log("[error]: cannot get user's session, signing out");
        setIsAuthorized(false);
      }
    };

    !isAuthorized && setUserSession();
  });

  useOnUpdate(() => {
    if (isAuthorized) return;

    cognito.signOut();
    resetStorage();
    setSession(null);

    pause();

    history.push('/app/login');
  }, [isAuthorized]);

  useOnUpdate(() => {
    if (!session) return;

    const scheduleRefresh = () => {
      try {
        const expiration = session.getIdToken().getExpiration();

        const refreshToken = session.getRefreshToken().getToken();

        // @ts-ignore
        intervalRef.current = setTimeout(
          async () => {
            try {
              console.log('[info]: about to renew user session');
              const session = await cognito.refreshSession(refreshToken);

              if (!Boolean(session)) {
                console.log("[error]: cannot renew user's session, session is empty, signing out");
                setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.SESSION);
                setIsAuthorized(false);
                return;
              }

              storeDetailsInStorage(session);
              setSession(session);
              setIsAuthorized(true);
              console.log('[info]: user session has been refreshed successfully');
            } catch (e) {
              console.log("[error]: cannot renew user's session, signing out");
              setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.SESSION);
              setIsAuthorized(false);
            }
          },
          expiration * 1000 - ONE_MINUTE - Date.now()
        );
      } catch (e) {
        console.log('[error]: schedule refresh, signing out');
        setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.SESSION);
        setIsAuthorized(false);
      }
    };

    scheduleRefresh();

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [session?.getAccessToken().getExpiration()]);

  useEffect(() => {
    if (IGNORE_LAST_LOGIN_PATHS.includes(location.pathname)) return;

    const { userId } = session?.getIdToken()?.payload || {};

    setLastLoginInformation({
      userId,
      pathname: `${location.pathname}${location.search}`,
    });
  }, [location, session?.getAccessToken().getExpiration()]);

  const signIn = useCallback(async (username: string, password: string) => {
    try {
      const session = await cognito.signIn(username, password);

      storeDetailsInStorage(session);
      setSession(session);
      setIsAuthorized(true);
    } catch (e) {
      setIsAuthorized(false);
      console.log('[error]: failed attempt to login');
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const federatedSignOut = useCallback(() => {
    setIsAuthorized(false);
    setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.LOGOUT);
    resetLastLoginInformation();
    resetLoginMethod();
  }, []);

  const signOut = useCallback(() => {
    if (getLoginMethod() === SSO) {
      window.location.href = [
        `https://${process.env.REACT_APP_COGNITO_DOMAIN}/logout?`,
        `client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&`,
        `logout_uri=${window.location.origin}/app/signout`,
      ].join('');

      return;
    }

    setIsAuthorized(false);
    setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.LOGOUT);
    resetLastLoginInformation();
  }, []);

  const resetPassword = useCallback(async (username, oldPassword, newPassword) => {
    try {
      const session = await cognito.resetPassword(username, oldPassword, newPassword);

      storeDetailsInStorage(session);
      setSession(session);
      setIsAuthorized(true);
    } catch (e) {
      setIsAuthorized(false);
      console.log('[error]: failed attempt to reset password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const forgotPassword = useCallback(async (username: string) => {
    try {
      await cognito.forgotPassword(username);
    } catch (e) {
      console.log('[error]: failed attempt to forgot password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const confirmPassword = useCallback(async (username: string, code: string, password: string) => {
    try {
      await cognito.confirmPassword(username, code, password);
    } catch (e) {
      console.log('[error]: failed attempt to confirm password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const changePassword = useCallback(async (username: string, oldPassword: string, newPassword: string) => {
    try {
      await cognito.changePassword(username, oldPassword, newPassword);
    } catch (e) {
      console.log('[error]: failed attempt to change password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const getShowIdlMessage = useCallback(() => {
    const value = getShowIdlMessageFromStorage();

    setShowIdlMessageInStorage(IDL_COGNITO_MESSAGE_VALUE.HIDE);
    return value;
  }, []);

  const federatedSingIn = useCallback(cognito.federatedSingIn, []);

  const getFederatedUserSession = useCallback(async () => {
    let session: CognitoUserSession;

    try {
      const { signInUserSession } = await cognito.getFederatedUserSession();

      session = signInUserSession;
    } catch (e) {
      console.log('[error]: failed attempt to get federated user session');
      console.log('[error]: redirecting user to hosted ui');

      return federatedSingIn();
    }

    storeDetailsInStorage(session);
    setSession(session);
    setIsAuthorized(true);

    setLoginMethod(SSO);
  }, []);

  const getLastLoginPath = useCallback(() => {
    const activeUser = session?.getIdToken()?.payload;

    if (!activeUser) return DEFAULT_PATH;

    const lastLogin = JSON.parse(localStorage.getItem(LAST_LOGIN) || `{ "pathname": "${DEFAULT_PATH}" }`);

    if (!lastLogin.userId || activeUser.userId === lastLogin.userId) {
      // to show job details we should set active wo to local storage first
      // and this is exactly what we do in /app/request-email
      if (lastLogin.pathname.includes('/app/work-orders/request-view')) {
        return lastLogin.pathname.replace('work-orders/request-view', 'request-email');
      }

      return lastLogin.pathname || DEFAULT_PATH;
    }

    return DEFAULT_PATH;
  }, [session?.getAccessToken().getExpiration()]);

  const getUser = useCallback(
    () => session?.getIdToken()?.payload,
    [session?.getAccessToken().getExpiration()]
  );

  return (
    <CognitoContext.Provider
      value={{
        signIn,
        isAuthorized,
        signOut,
        resetPassword,
        forgotPassword,
        confirmPassword,
        getShowIdlMessage,
        changePassword,
        federatedSingIn,
        federatedSignOut,
        getFederatedUserSession,
        getLastLoginPath,
        getUser,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};
