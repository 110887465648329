import { useEffect } from 'react';

const useBeforeUnload = (fn: () => void) =>
  useEffect(() => {
    window.addEventListener('beforeunload', fn);
    return () => {
      window.removeEventListener('beforeunload', fn);
    };
  }, []);

export default useBeforeUnload;
