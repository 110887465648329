import React from 'react';
import { Box } from '@mui/material';

type Props = {
  children: React.ReactNode;
  onSubmit: () => any;
};

export const BaseLoginForm = ({ children, onSubmit }: Props) => (
  <Box component="form" bgcolor="white" onSubmit={onSubmit} borderRadius={0.75} maxWidth={400} minWidth={320}>
    <>{children}</>
  </Box>
);
