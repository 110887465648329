import { useCallback, useState } from 'react';

type UseTableOptions = {
  searchBy: string;
  page: number;
  rowsPerPage: number;
};

// FYI backend counts pages from 1, frontend from 0
const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 10;

const useTable = (options?: UseTableOptions) => {
  const [state, setState] = useState({
    searchBy: options?.searchBy || '',
    page: options?.page || DEFAULT_PAGE,
    rowsPerPage: options?.rowsPerPage || DEFAULT_ROWS_PER_PAGE,
  });

  const onPageChange = useCallback(
    // prettier-ignore
    (e: any, page: number) => setState((s) => ({
      ...s,
      page: page
    })),
    []
  );

  const onRowsPerPageChange = useCallback(
    // prettier-ignore
    (e: any) => setState((s) => ({
      ...s,
      rowsPerPage: e.target.value,
      page: DEFAULT_PAGE,
    })),
    []
  );

  const onSearch = useCallback(
    // prettier-ignore
    (searchBy: string) => setState((s) => ({
      ...s,
      searchBy,
      page: DEFAULT_PAGE,
    })),
    []
  );

  const onSearchClear = useCallback(
    // prettier-ignore
    () => setState((s) => ({
      ...s,
      searchBy: '',
      page: DEFAULT_PAGE,
    })),
    []
  );

  return {
    ...state,
    onSearchClear,
    onSearch,
    onPageChange,
    onRowsPerPageChange,
  };
};

export default useTable;
