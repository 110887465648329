import { Userpilot } from 'userpilot';
import { getUserData } from '@uptime/shared/utils/general';

if (process.env.REACT_APP_ENV === 'production') {
  Userpilot.initialize('NX-de9c5e9e');
}

export const reload = () => {
  if (process.env.REACT_APP_ENV !== 'production') return;

  Userpilot.reload();
};

export const initialize = ({
  userId,
  accountId,
  email,
  profile,
  isTopLevel,
  isManager,
  isSubAccount,
  isRepairer,
  lastLogin,
}: any) => {
  if (process.env.REACT_APP_ENV !== 'production') return;

  const { businessName, userFullName } = getUserData(profile);

  const role = `${
    isRepairer
      ? 'repairer'
      : isManager
        ? 'facility manager'
        : isSubAccount
          ? 'facility staff'
          : 'facility top'
  }`;

  Userpilot.identify(userId, {
    name: userFullName,
    email,
    company: {
      id: accountId,
      name: businessName,
    },
    role,
    isTopLevel,
    lastLogin,
  });
};
