import React from 'react';

import { Box, Fade, Grid, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

type Props = {
  children: React.ReactNode;
};

export const BaseLoginInfo = ({ children }: Props) => {
  const theme = useTheme();

  return (
    <Fade in={Boolean(children)} mountOnEnter unmountOnExit timeout={100}>
      <Box
        component={Grid}
        container
        item
        xs={12}
        border="1px solid #ddd"
        borderRadius="3px"
        mb={2}
        data-testid="loginInfoMessage"
      >
        <Box
          component={Grid}
          item
          bgcolor={theme.palette.secondary.light1}
          container
          maxWidth="36px !important"
          alignItems="center"
          justifyContent="center"
        >
          <InfoIcon color="primary" fontSize="small" />
        </Box>

        <Box component={Grid} item p={1.5}>
          <Typography
            sx={{
              overflowX: 'hidden',
              whiteSpace: 'wrap',
              width: '248px',
            }}
            component="div"
            variant="body2"
            color={theme.palette.grey[800]}
          >
            <>{children}</>
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};
