import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react/dist';
import * as Sentry from '@sentry/react';

export const initialize = (userInfo: any) => {
  if (process.env.REACT_APP_ENV !== 'production') return;

  LogRocket.init('tikuk6/uptimehealth');
  LogRocket.identify(userInfo.userId.toString(), {
    ...userInfo,
  });
  setupLogRocketReact(LogRocket);

  LogRocket.getSessionURL(function (sessionURL) {
    // @ts-ignore
    gtag('event', 'LogRocket', { sessionURL });
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
};
