import { useRef, useEffect } from 'react';

const useOnUnmount = (fn: () => any): void => {
  const fnRef = useRef(fn);

  fnRef.current = fn;

  useEffect(() => () => fnRef.current(), []);
};

export default useOnUnmount;
